<script>
//import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";
//import car from "./insert_veiculo.vue";
//import rota from "./insert_rota.vue"

import { http } from "../../../../helpers/easyindustriaapi/config";
export default {
  components: {
   // Multiselect,
  //  car,
 //   rota
  },
  created(){
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
  },
  props: {
    showModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      rota: {
        nome: null,
      },
      loader: { get: false },
      titleBody: "Incluir Rota",
      tabelas: {
        ttp_nome: null,
      },
      motorista: [
        {
          id: 1,
          nome: "motorista1",
        },
      ],
      veiculos: [
        {
          id: 1,
          nome: "veiculos1",
        },
      ],
      rotas: [
        {
          id: 1,
          nome: "rota1",
        },
      ],
      insert_embarque: {
        motorista_id: null,
        veiculo_id: null,
        rota_id: null,
        identificacao: null,
        notas: null,
        data_carregamento: null,
        data_previsao: null,
        obs: null,
      },
      select_motorista: null,
      select_rota: null,
      select_veiculos: null,
      open_modal_car: false,
      open_modal_rota: false,
    };
  },
  validations() {
    return {
      tabelas: {
        ttp_nome: { required },
      },
    };
  },
  mounted() {
    this.setNewTitle();
  },
  methods: {
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    // back() {
    //   this.$emit("back");
    // },
    async doPost(rota) {
      rota = this.rota;
      rota.empresa_id = this.currentEmpresa.id;
      // embarque.user_id = this.currentUser.id;
      //this.onLoader();
      try {
        let response = await http.post(
          "/rota?empresa_id=" + this.currentEmpresa.id,
          rota
        );
        if (response) {
          //this.offLoader();
          if (response.status === 200) {
            //this.getData();
            //this.inserindo = false;
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    modal_car() {
      this.open_modal_car = true;
    },
    modal_rota() {
      this.open_modal_rota = true;
    },
    back() {
      this.open_modal_rota = false;
      this.open_modal_car = false;
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPost() {
      this.$emit("doPost", this.rota);
      this.voltar();
    },
    voltar() {
      this.$emit("back");
    },
    seleciona_moto(event) {
      //console.log(event)
      this.insert_embarque.motorista_id = event.id;
    },
    seleciona_rota(event) {
      this.insert_embarque.rota_id = event.id;
      //console.log(event)
    },
    seleciona_veiculo(event) {
      this.insert_embarque.veiculo_id = event.id;
      ///console.log(event)
    },
    customLabel_car({ id, nome }) {
      return `${id} – ${nome}`;
    },
    customLabel_rota({ id, nome }) {
      return `${id} – ${nome}`;
    },
  },
};
</script>

<template>
  <div class="card">
      <div class="card-body">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <Label>Rota:</Label>
            <input
              v-model="rota.nome"
              type="text"
              class="form-control text-left"
              placeholder="digite a rota"
            />
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center mt-4">
          <div class="col-md-12 text-center">
            <button class="bnt btn-secondary" @click="setPost()">salvar</button>
          </div>
        </div>
      </div>
    </div>
</template>